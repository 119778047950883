import { createSelector } from "reselect"
import { selectUserInfo } from "../user/user.selectors"
import { UserInfo } from "../../App"
import { selectOrgId } from "../org/org.selectors"
import { Resource, Action, Permission } from "learnink-common/lib/domains/permissions/Permission.model"

export const selectPermissions = createSelector(
	[selectUserInfo, selectOrgId],
	(userInfo: UserInfo, orgId: string) => new Permissions(userInfo, orgId)
)

export class Permissions {
	private readonly permissions: Permission[]

	constructor(private userInfo: UserInfo | undefined, private orgId: string) {
		this.permissions = userInfo?.permissions[orgId] || []
	}

	private canPerformAction = (resource: Resource, action: Action, permissions: Permission[]) => {
		return (permissions || []).some((p: Permission) => p.action === action && p.resource === resource)
	}

	canEdit = (resource: Resource): boolean => {
		return this.canPerformAction(resource, Action.enum.EDIT, this.permissions)
	}

	canView = (resource: Resource): boolean => {
		return this.canPerformAction(resource, Action.enum.VIEW, this.permissions) || this.canEdit(resource)
	}

	canViewSome = (resources: Resource[]): boolean => {
		return resources.some(this.canView)
	}

	canEditSome = (resources: Resource[]): boolean => {
		return resources.some(this.canEdit)
	}

	canViewAll = (resources: Resource[]): boolean => {
		return resources.every(this.canView)
	}

	canEditAll = (resources: Resource[]): boolean => {
		return resources.every(this.canEdit)
	}

	getPermissions(): Permission[] {
		return this.permissions
	}
}

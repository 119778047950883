import React from "react"
import styled from "styled-components"

import SingleQuizQuestionCard from "./SingleQuizQuestionCard"
import SingleQuestionData from "./SingleQuestionData"

const Panel = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	min-width: 0;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
`
const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const SingleQuestionPanel = ({ singleQuestionContent, accuracyData, questionData }) => {
	return (
		<Panel>
			<SingleQuizQuestionCard singleQuestionContent={singleQuestionContent} />
			<RightSection>
				<SingleQuestionData
					singleQuestionContent={singleQuestionContent}
					accuracyData={accuracyData}
					questionData={questionData}
				/>
			</RightSection>
		</Panel>
	)
}

export default SingleQuestionPanel

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import axios from "axios"
import { Alert, Button, Space } from "antd"

import { selectOrgId } from "../../../redux/org/org.selectors"
import { bulkDeleteUserState } from "../../../redux/management/management.actions"

import GeTitle from "../../common/GeTitle"
import CbLoading from "../../common/CbLoading"

const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
`
const LabelContainer = styled.div`
	width: 100%;
`
const FlexRowContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const LabelText = styled.h1`
	font-size: 17px;
	font-weight: 600;
	text-align: left;
	line-height: 24px;
	margin-right: 6px;
`
const UserDataContainer = styled.div`
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
	border: solid 1px grey;
	margin-top: 10px;
	margin-bottom: 10px;
`
const UserText = styled.p`
	font-size: 14px;
	margin-left: 12px;
	margin-top: 8px;
`
const Divider = styled.div`
	height: 32px;
	width: 100%;
`

const DeleteUsersModal = ({ orgId, usersData, cancelModal, bulkDeleteUserState, getUsers }) => {
	const [uiStatus, setUiStatus] = useState("default")
	const [responseData, setResponseData] = useState(null)

	useEffect(getUsers, [])

	// update status to control modal view
	const delayUiUpdateWith = (status) => {
		setTimeout(() => {
			setUiStatus(status)
		}, 300)
	}

	const delayUserListUpdate = (data) => {
		setTimeout(() => {
			bulkDeleteUserState(data)
		}, 2000)
	}

	// function to send notifcation to user API
	const deleteUsers = async () => {
		setUiStatus("loading")
		try {
			const { data } = await axios.put(`/api/management/${orgId}/users/delete`, {
				user_ids: usersData.map((x) => x.userId)
			})
			if (data) {
				setResponseData(data)
				delayUiUpdateWith("response")
				delayUserListUpdate(data)
			} else {
				delayUiUpdateWith("error")
			}
		} catch (err) {
			delayUiUpdateWith("error")
		}
	}

	return (
		<ModalInner>
			<>
				{uiStatus === "default" && (
					<>
						<GeTitle underline={false}>Delete users</GeTitle>
						<LabelContainer>
							<FlexRowContainer style={{ justifyContent: "flex-start" }}>
								<LabelText>
									Are you sure you want to delete the following{" "}
									<span style={{ color: "var(--secondary-color)" }}>{usersData.length} users</span>?
								</LabelText>
							</FlexRowContainer>
							<UserDataContainer>
								{usersData.map((x, index) => (
									<UserText style={{ color: "var(--secondary-color)" }} key={index}>
										• {x.customName}
									</UserText>
								))}
							</UserDataContainer>
						</LabelContainer>
						<LabelContainer>
							<Alert
								message="This user information will be completely deleted from your dashboard and cannot be retrieved"
								type="warning"
							/>
						</LabelContainer>

						<Divider />

						<FlexRowContainer>
							<Space>
								<Button shape="round" size="large" onClick={() => cancelModal()}>
									Cancel
								</Button>
								<Button shape="round" size="large" type="primary" onClick={() => deleteUsers()}>
									Proceed
								</Button>
							</Space>
						</FlexRowContainer>
					</>
				)}

				{uiStatus === "loading" && (
					<>
						<GeTitle underline={false}>Updating...</GeTitle>
						<CbLoading size={40} />
					</>
				)}

				{uiStatus === "response" && (
					<>
						<GeTitle underline={false}>Summary</GeTitle>
						<Space>
							{responseData && responseData.success.length > 0 && (
								<Alert message={`${responseData.success.length} users deleted`} type="success" />
							)}

							{responseData && responseData.failed.length > 0 && (
								<Alert message={`${responseData.failed.length} users failed to delete`} type="error" />
							)}
						</Space>
					</>
				)}

				{uiStatus === "error" && (
					<>
						<GeTitle underline={false}>Error</GeTitle>
						<Alert type="error" message="Apologies, something went wrong while updating" />
					</>
				)}
			</>
		</ModalInner>
	)
}

DeleteUsersModal.propTypes = {
	orgId: PropTypes.string.isRequired,
	usersData: PropTypes.array.isRequired,
	cancelModal: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	bulkDeleteUserState: (data) => dispatch(bulkDeleteUserState(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUsersModal)

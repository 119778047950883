import { ManagementActionTypes } from "./management.types"
import { setOrgData } from "../org/org.actions"
import axios from "axios"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const getUserListForOrg = (orgId, orgUserGroups, learningPathById) => async (dispatch) => {
	try {
		await updateFirebaseToken()
		const { data } = await axios.post(`/api/userManagement/${orgId}/users/search`, {
			limit: 50000,
			searchFilters: [{ member: "registration_status", operator: "in", values: ["registered", "unregistered"] }]
		})
		data.forEach((row) => {
			row.userGroupNames = row.userGroups.map((item) =>
				orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
			)
			row.learningPathName = learningPathById[row.learningPathId]
				? learningPathById[row.learningPathId].internalName
				: ""
		})
		dispatch({
			type: ManagementActionTypes.SET_USER_LIST,
			payload: data
		})
	} catch (err) {
		console.log(err)
		throw err
	}
}

export const clearOrgUserList = () => (dispatch) => {
	dispatch({
		type: ManagementActionTypes.CLEAR_USER_LIST
	})
}

// used to initiate update for user groups of given user
export const updateUserOrgInfo = (org_id, user_id, user_info, userGroupsNamesMap) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/management/${org_id}/users/${user_id}`, {
			user_info
		})
		if (res.data) {
			const { user_id, custom_id, custom_name, user_groups } = res.data
			const userGroups = user_groups.filter((item) => item !== "registered")
			const userGroupNames = userGroups
				.filter((item) => item !== "registered")
				.map((item) => userGroupsNamesMap[item])
			dispatch({
				type: ManagementActionTypes.UPDATED_ONE_IN_USER_LIST,
				payload: {
					userId: user_id,
					customId: custom_id,
					customName: custom_name,
					userGroups,
					userGroupNames
				}
			})
			return res.data
		} else {
			const err = {
				message: "Invalid response"
			}
			throw err
		}
	} catch (err) {
		throw err.response.data
	}
}

export const bulkEditUserGroupState = (data, userGroupsNamesMap) => async (dispatch) => {
	const rowsToUpdate = []
	data.forEach((row) => {
		const { user_id, user_groups } = row
		const userGroups = user_groups.filter((item) => item !== "registered")
		rowsToUpdate.push({
			userId: user_id,
			userGroups,
			userGroupNames: userGroups.map((item) => userGroupsNamesMap[item])
		})
	})
	dispatch({
		type: ManagementActionTypes.BULK_EDIT_USER_GROUPS,
		payload: rowsToUpdate
	})
}

export const bulkEditLearningPath = (data, learningPathById) => (dispatch) => {
	const rowsToUpdate = []
	data.forEach((row) => {
		const { userId, learningPathId } = row
		rowsToUpdate.push({
			userId,
			learningPathId,
			learningPathName: learningPathById[learningPathId] ? learningPathById[learningPathId].internalName : ""
		})
	})
	dispatch({
		type: ManagementActionTypes.BULK_EDIT_LEARNING_PATH,
		payload: rowsToUpdate
	})
}

export const archiveUsers = (orgId, userIds) => async (dispatch) => {
	try {
		await updateFirebaseToken()
		const { data } = await axios.post(`/api/userManagement/${orgId}/users/archive`, {
			userIds
		})
		const archivedUserIds = data.filter((item) => item.registrationStatus === "archived").map((item) => item.userId)
		if (archivedUserIds.length > 0) {
			dispatch({
				type: ManagementActionTypes.REMOVE_FROM_USER_LIST,
				payload: userIds
			})
		}
		return data
	} catch (err) {
		console.log(err)
		throw err
	}
}

export const unarchiveUsers = (orgId, userIds, orgUserGroups) => async (dispatch) => {
	try {
		await updateFirebaseToken()
		const { data } = await axios.post(`/api/userManagement/${orgId}/users/unarchive`, {
			userIds
		})
		data.forEach((row) => {
			row.userGroupNames = row.userGroups.map((item) =>
				orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
			)
		})
		dispatch({
			type: ManagementActionTypes.ADD_TO_USER_LIST,
			payload: data
		})
		return data
	} catch (err) {
		console.log(err)
		throw err
	}
}

export const bulkDeleteUserState = (data) => async (dispatch) => {
	if (data && data.success && data.success.length > 0) {
		const userIds = data.success.map((item) => item.user_id)
		dispatch({
			type: ManagementActionTypes.REMOVE_FROM_USER_LIST,
			payload: userIds
		})
	}
}

// create a new user group on user app and then update everything locally
export const createNewUserGroup = (org_id, createParams) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.post(`/api/userGroup/${org_id}`, createParams)
		// we force a refresh of org data here to ensure we set new user group
		// on orgData state in redux...
		dispatch(setOrgData(org_id))
	} catch (err) {
		console.log(err)
		throw err
	}
}

export const updateUserGroup = (org_id, userGroupId, updateParams) => async (dispatch) => {
	updateFirebaseToken()
	await axios.post(`/api/userGroup/${org_id}/${userGroupId}`, updateParams)
	dispatch(setOrgData(org_id))
}

export const regenerateUserGroupCode = (org_id, user_group_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.get(`/api/management/${org_id}/user-groups/regenerate-code/${user_group_id}`)
		if (res && res.data && res.data.access_code) {
			dispatch(setOrgData(org_id))
			return res.data.access_code
		} else {
			throw new Error("Error regenerating code, please try again")
		}
		// update redux state with new access codes
	} catch (err) {
		console.log(err)
		throw err
	}
}

// delete a user group on user app, then update everything locally
export const deleteUserGroup = (org_id, user_group_id) => (dispatch) => {
	updateFirebaseToken()
	axios
		.delete(`/api/management/${org_id}/user-groups/${user_group_id}`)
		.then((res) => {
			// we force a refresh of org data here to ensure we set remove user group
			// from orgData state in redux...
			dispatch(setOrgData(org_id))
		})
		.catch((err) => {
			console.log(err)
		})
}

// sets all the user groups info for a given org
export const setOrgUserGroupsInfo = (org_id, orgUserGroups) => (dispatch) => {
	updateFirebaseToken()
	axios
		.get(`/api/management/${org_id}/user-groups`)
		.then((res) => {
			const data = res.data
			const orgUserGroupsInfo = []
			orgUserGroups
				.filter((userGroup) => data[userGroup.id])
				.forEach((userGroup) => {
					orgUserGroupsInfo.push({
						id: userGroup.id,
						name: userGroup.name,
						description: userGroup.description,
						color: userGroup.color,
						accessCode: userGroup.accessCode,
						type: userGroup.type || "tag",
						learningPaths: userGroup.learningPaths,
						learningPathCount: userGroup.learningPaths.length,
						userCount: data[userGroup.id].user_count,
						courseCount: data[userGroup.id].course_ids.length,
						resourceCount: data[userGroup.id].resource_ids.length,
						courseIds: data[userGroup.id].course_ids,
						courseNames: data[userGroup.id].course_names,
						resourceIds: data[userGroup.id].resource_ids,
						resourceNames: data[userGroup.id].resource_names,
						accessCodes: data[userGroup.id].access_codes,
						accessCodeCount: data[userGroup.id].access_codes.length
					})
				})
			dispatch({
				type: ManagementActionTypes.SET_ORG_USER_GROUPS_INFO,
				payload: orgUserGroupsInfo
			})
		})
		.catch((err) => {
			console.log(err)
		})
}

export const clearOrgUserGroupsInfo = () => (dispatch) => {
	dispatch({
		type: ManagementActionTypes.CLEAR_ORG_USER_GROUPS_INFO
	})
}

import { StringMap } from "./types"

export type UrlFunction = (params?: StringMap) => string

const buildUrl = (path: string): UrlFunction => {
	return (params) => {
		const matches: RegExpMatchArray | null = path.match(/:[a-zA-Z]+/g)

		if (matches && matches.length > 0 && params) {
			matches.forEach((match) => {
				const key = match.replace(":", "")
				path = path.replace(match, params[key])
			})
		}

		return path
	}
}

export type Method = "GET" | "POST" | "PUT" | "DELETE"

export interface Route {
	url: UrlFunction
	method: Method
	noAuthHeader?: boolean
	isMultipart?: boolean
}

const Routes = {
	getCreateStageDefaults: { url: buildUrl("/api/courses/modules/create-stage/defaults"), method: "GET" },
	getVoiceProfileAIDefaults: { url: buildUrl("/api/voice-profiles/defaults"), method: "GET" },
	requestVoiceClips: { url: buildUrl("/api/courses/stage/:stageId/voice-clip-request"), method: "POST" },
	processAudioFile: {
		url: buildUrl("/api/courses/stage/:stageId/process-audio-file"),
		method: "POST",
		isMultipart: true
	},
	userSearch: { url: buildUrl("/api/userManagement/:orgId/users/search"), method: "POST" },
	userCount: { url: buildUrl("/api/userManagement/:orgId/users/count"), method: "POST" },
	userSearchOptions: { url: buildUrl("/api/userManagement/:orgId/users/options"), method: "POST" },
	shareRequestSenders: { url: buildUrl("/api/share-requests/:orgId/find-senders"), method: "GET" }
} as const

export default Routes

import moment from "moment"
import {
	DefaultColumnFilter,
	SelectColumnFilter,
	NumberRangeColumnFilter,
	SliderColumnFilter,
	multiSelectFilter,
	shareRequestFilter
} from "../../tables/tableFunctions"
import UserGroupFilter from "../../tables/UserGroupFilter"
import ShareRequestFilter from "../../tables/ShareRequestFilter"

function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id]
		return rowValue >= filterValue
	})
}

export const userTableColumns = [
	{
		Header: "Full name",
		id: "customName",
		accessor: "customName",
		Filter: DefaultColumnFilter,
		width: 250
	},
	{
		Header: "Status",
		id: "registrationStatus",
		accessor: "registrationStatus",
		Filter: SelectColumnFilter,
		filter: "equals",
		width: 100
	},
	{
		Header: "Completion status",
		id: "status",
		accessor: "status",
		Filter: SelectColumnFilter,
		filter: "equals",
		width: 120
	},
	{
		Header: "Referrer",
		id: "shareRequestSenders",
		accessor: "shareRequestSenders",
		Filter: ShareRequestFilter,
		filter: shareRequestFilter,
		width: 200
	},
	{
		Header: "User groups",
		id: "userGroupNames",
		accessor: "userGroupNames",
		Filter: UserGroupFilter,
		filter: multiSelectFilter,
		width: 250
	},
	{
		Header: "Learning path",
		id: "learningPathName",
		accessor: "learningPathName",
		Filter: SelectColumnFilter,
		filter: "equals",
		width: 120
	},
	{
		Header: "Start date",
		id: "startedAt",
		Filter: DefaultColumnFilter,
		accessor: (data) => {
			let val = null
			if (data.startedAt) {
				const date = moment(data.startedAt)
				if (date.isValid()) {
					val = date.format("YYYY-MM-DD")
				}
			}
			return val
		}
	},
	{
		Header: "Completed date",
		id: "completedAt",
		Filter: DefaultColumnFilter,
		accessor: (data) => {
			let val = null
			if (data.completedAt) {
				const date = moment(data.completedAt)
				if (date.isValid()) {
					val = date.format("YYYY-MM-DD")
				}
			}
			return val
		}
	},
	{
		Header: "Hours to complete",
		id: "hoursToComplete",
		accessor: "hoursToComplete",
		Filter: SliderColumnFilter,
		filter: filterGreaterThan
	},
	{
		Header: "Av final score (%)",
		id: "averageScore",
		accessor: "averageScore",
		Filter: NumberRangeColumnFilter,
		filter: "between"
	},
	{
		Header: "Due date",
		id: "dueDate",
		accessor: "dueDate",
		Filter: DefaultColumnFilter
	},
	{
		Header: "Num courses complete",
		id: "numCoursesCompleted",
		accessor: "numCoursesCompleted",
		Filter: NumberRangeColumnFilter,
		filter: "between"
	},
	{
		Header: "Num sequences complete",
		id: "numSequencesCompleted",
		accessor: "numSequencesCompleted",
		Filter: SelectColumnFilter,
		filter: "between"
	},
	{
		Header: "First name",
		id: "firstName",
		accessor: "firstName",
		Filter: DefaultColumnFilter
	},
	{
		Header: "Last name",
		id: "lastName",
		accessor: "lastName",
		Filter: DefaultColumnFilter
	},
	{
		Header: "Last active",
		id: "lastActive",
		Filter: DefaultColumnFilter,
		accessor: (data) => {
			let val = null
			if (data.lastActive) {
				const date = moment(data.lastActive)
				if (date.isValid()) {
					val = date.format("YYYY-MM-DD hh:mm")
				}
			}
			return val
		}
	},
	{
		Header: "Custom ID",
		id: "customId",
		accessor: "customId",
		Filter: DefaultColumnFilter
	},
	{
		Header: "User ID",
		id: "userId",
		accessor: "userId", // accessor is the "key" in the data
		Filter: DefaultColumnFilter,
		width: 290
	},
	{
		Header: "Phone number",
		id: "phoneNumber",
		accessor: "phoneNumber",
		Filter: DefaultColumnFilter
	},
	{
		Header: "Sex",
		id: "sex",
		accessor: "sex",
		Filter: SelectColumnFilter, // to learn more about these filters see tableFunctions
		filter: "equals"
	},
	{
		Header: "Country",
		id: "country",
		accessor: "country",
		Filter: SelectColumnFilter,
		filter: "equals"
	},
	{
		Header: "Region",
		id: "region",
		accessor: "region",
		Filter: SelectColumnFilter,
		filter: "equals"
	},
	{
		Header: "Age",
		id: "ageBracket",
		accessor: "ageBracket",
		Filter: SelectColumnFilter,
		filter: "equals"
	},
	{
		Header: "Registration date",
		id: "registeredAt",
		Filter: DefaultColumnFilter,
		accessor: (data) => {
			let val = null
			if (data.registeredAt) {
				const date = moment(data.registeredAt)
				if (date.isValid()) {
					val = date.format("YYYY-MM-DD")
				}
			}
			return val
		}
	},
	{
		Header: "User score (%)",
		id: "userScore",
		accessor: "userScore",
		Filter: NumberRangeColumnFilter,
		filter: "between"
	},
	{
		Header: "Baseline accuracy (%)",
		id: "baselineAccuracy",
		accessor: "baselineAccuracy",
		Filter: NumberRangeColumnFilter,
		filter: "between"
	},
	{
		Header: "Baseline answers (Correct)",
		id: "numBaselineAnswersCorrect",
		accessor: "numBaselineAnswersCorrect",
		Filter: NumberRangeColumnFilter,
		filter: filterGreaterThan
	},
	{
		Header: "Baseline answers (Total)",
		id: "numBaselineAnswers",
		accessor: "numBaselineAnswers",
		Filter: NumberRangeColumnFilter,
		filter: filterGreaterThan
	},
	{
		Header: "Endline accuracy (%)",
		id: "endlineAccuracy",
		accessor: "endlineAccuracy",
		Filter: NumberRangeColumnFilter,
		filter: "between"
	},
	{
		Header: "Endline answers (Correct)",
		id: "numEndlineAnswersCorrect",
		accessor: "numEndlineAnswersCorrect",
		Filter: NumberRangeColumnFilter,
		filter: filterGreaterThan
	},
	{
		Header: "Endline answers (Total)",
		id: "numEndlineAnswers",
		accessor: "numEndlineAnswers",
		Filter: NumberRangeColumnFilter,
		filter: filterGreaterThan
	}
]

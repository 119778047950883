import React from "react"
import styled from "styled-components"
import { Empty, Typography } from "antd"
import * as S from "../../styles/PageStyles"

import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

const { Title } = Typography

const Container = styled.div`
	width: 100%;
	float: left;
	padding: 0px 20px 20px 0px;
	@media (min-width: 850px) {
		width: 260px;
	}
	@media (min-width: 1110px) {
		width: 260px;
	}
`
const Card = styled.div`
	display: flex;
	flex-direction: column;
	height: 200px;
	background: white;
	border: solid 1px var(--light-grey);
	border-radius: 12px;
	transition: background 0.5s;
	cursor: pointer;
	:hover {
		background: var(--secondary-color-pale);
	}
`
const CourseCardFooter = styled.div`
	width: 100%;
	text-align: center;
	border-top: solid 1px var(--light-grey);
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const LearningPathContainer = styled.div`
	width: 50%;
	height: 50%;
	padding: 4px;
	text-align: center;
`
const LearningPathBox = styled.div`
	background: orange;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	color: white;
	font-weight: bold;
	font-size: 11px;
	text-transform: uppercase;
	border-radius: 4px;
`

interface Props {
	userGroup: UserGroup
	selectRole: (id: string) => void
	roleLearningPaths: LearningPath[]
}

const RoleContainer = ({ userGroup, selectRole, roleLearningPaths }: Props) => {
	const filteredLearningPaths = roleLearningPaths.filter(
		(item) => !["PAST", "ARCHIVED"].includes(new LearningPathContent(item).getStatus())
	)

	return (
		<Container>
			<Card onClick={() => selectRole(userGroup.id)}>
				{filteredLearningPaths.length === 0 ? (
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description="No learning paths"
						style={{ height: 87 }}
					/>
				) : (
					<S.FlexBoxStartRow style={{ flex: 1, padding: 10 }}>
						<LearningPathContainer>
							<LearningPathBox
								style={{
									backgroundColor: new LearningPathContent(filteredLearningPaths[0]).getColor()
								}}
							>
								{filteredLearningPaths[0].internalName}
							</LearningPathBox>
						</LearningPathContainer>
						<LearningPathContainer>
							{filteredLearningPaths.length > 1 && (
								<LearningPathBox
									style={{
										backgroundColor: new LearningPathContent(filteredLearningPaths[1]).getColor()
									}}
								>
									{filteredLearningPaths[1].internalName}
								</LearningPathBox>
							)}
						</LearningPathContainer>
						<LearningPathContainer>
							{filteredLearningPaths.length > 2 && (
								<LearningPathBox
									style={{
										backgroundColor: new LearningPathContent(filteredLearningPaths[2]).getColor()
									}}
								>
									{filteredLearningPaths[2].internalName}
								</LearningPathBox>
							)}
						</LearningPathContainer>
						<LearningPathContainer>
							{filteredLearningPaths.length === 4 && (
								<LearningPathBox
									style={{
										backgroundColor: new LearningPathContent(filteredLearningPaths[3]).getColor()
									}}
								>
									{filteredLearningPaths[3].internalName}
								</LearningPathBox>
							)}
							{filteredLearningPaths.length > 4 && (
								<LearningPathBox
									style={{
										backgroundColor: "var(--mid-grey)"
									}}
								>
									+{filteredLearningPaths.length - 3}
								</LearningPathBox>
							)}
						</LearningPathContainer>
					</S.FlexBoxStartRow>
				)}
				<CourseCardFooter style={{ padding: "0px 10px 0px 10px" }}>
					<Title level={5} style={{ marginBottom: 0 }} ellipsis>
						{userGroup.name}
					</Title>
				</CourseCardFooter>
			</Card>
		</Container>
	)
}

export default RoleContainer

import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { UUID } from "learnink-common/lib/types/types"
import { SequenceContent } from "./SequenceContent"
import { Sequence, UnlockTrigger } from "learnink-common/lib/domains/sequences/Sequence.model"
import _ from "lodash"
import { ShortIdGenerator } from "learnink-common/lib/tools/randomCharacterGenerators/ShortIdGenerator"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { CourseCards } from "../../../util/model/OrgSelectors"

export interface UpdatedSequences {
	[key: UUID]: "added" | "deleted" | "updated"
}

export const triggerLabels = {
	finish_previous: {
		title: "Continuous",
		label: "unlock on previous complete",
		color: "purple"
	},
	start_learning_path: {
		title: "Staggered",
		label: "unlock after deadline",
		color: "cyan"
	}
}

export type UnlockStatus = "unlocked" | "active" | "time_locked" | "locked"

export interface CourseStatus {
	status: UnlockStatus
	unlockDay?: number
}

export interface CourseStatuses {
	[key: UUID]: CourseStatus
}

export type LearningPathStatus = "ACTIVE" | "PAST" | "SCHEDULED" | "ARCHIVED"

export class LearningPathContent {
	rawContent: LearningPath

	constructor(rawContent: LearningPath) {
		this.rawContent = rawContent || {}
	}

	getColor() {
		const learningPath = this.rawContent
		let color = new ShortIdGenerator().convertToColor(learningPath.id)
		if (learningPath.isArchived) {
			color = "var(--light-grey)"
		}
		return color
	}

	getStatus() {
		const learningPath = this.rawContent
		let status: LearningPathStatus = "ACTIVE"
		if (learningPath.isArchived) {
			status = "ARCHIVED"
		}
		return status
	}

	getTotalTrainingTime(courseCards: CourseCards) {
		const learningPath = this.rawContent
		let trainingTimeMins = 0
		const sequences = learningPath.sequences
		sequences.forEach((sequence: Sequence) => {
			trainingTimeMins = trainingTimeMins + new SequenceContent(sequence).getTotalTrainingTimeMins(courseCards)
		})
		return trainingTimeMins
	}

	getNumModuleStages(courseCards: CourseCards) {
		const learningPath = this.rawContent
		let numModules = 0
		const sequences = learningPath.sequences
		sequences.forEach((sequence: Sequence) => {
			numModules = numModules + new SequenceContent(sequence).getNumModuleStages(courseCards)
		})
		return numModules
	}

	getCourseIds() {
		const learningPath = this.rawContent
		const courseIds: UUID[] = []
		const sequences = learningPath.sequences
		sequences.forEach((sequence: Sequence) => {
			sequence.content.forEach((item) => {
				if (item.type === "course") {
					courseIds.push(item.targetId)
				}
			})
		})
		return courseIds
	}

	getLengthInDays() {
		const learningPath = this.rawContent
		if (learningPath.sequences.length > 0) {
			return learningPath.sequences[learningPath.sequences.length - 1].deadlineDay + 1
		} else {
			return 0
		}
	}

	getLearningPathRole(roleUserGroups: UserGroup[]) {
		const learningPathId = this.rawContent.id
		let userGroupId = undefined
		roleUserGroups.forEach((userGroup) => {
			const learningPathIds = userGroup.learningPaths.map((item) => item.learningPathId)
			if (learningPathIds.includes(learningPathId)) {
				userGroupId = userGroup.id
			}
		})
		return userGroupId
	}

	getUnlockTrigger() {
		const learningPath = this.rawContent
		let unlockTrigger: UnlockTrigger = "start_learning_path"
		if (learningPath.sequences.length > 1 && learningPath.sequences[1].unlockTrigger === "finish_previous") {
			unlockTrigger = "finish_previous"
		}
		return unlockTrigger
	}

	recalculateTimeline() {
		const learningPath = { ...this.rawContent }
		learningPath.sequences.forEach((sequence, sequenceIndex) => {
			const unlockDelayDays =
				sequenceIndex === 0 || sequence.unlockTrigger === "finish_previous"
					? 0
					: learningPath.sequences[sequenceIndex - 1].deadlineDay + 1
			if (sequence.staggerProgress && sequence.daysPerCourse) {
				const numCourses = sequence.content.filter((item) => item.type === "course").length
				const numDays = sequence.daysPerCourse * numCourses
				learningPath.sequences[sequenceIndex].numDays = numDays
				learningPath.sequences[sequenceIndex].deadlineDay = unlockDelayDays + numDays - 1
			} else {
				const numDays =
					learningPath.sequences[sequenceIndex].deadlineDay -
					learningPath.sequences[sequenceIndex].unlockDelayDays +
					1
				learningPath.sequences[sequenceIndex].numDays = numDays
				learningPath.sequences[sequenceIndex].deadlineDay = unlockDelayDays + numDays - 1
			}
			learningPath.sequences[sequenceIndex].unlockDelayDays = unlockDelayDays
		})
		return learningPath
	}

	getCourseStatuses() {
		const learningPath = this.rawContent
		const unlockTrigger = this.getUnlockTrigger()
		const courseStatuses: CourseStatuses = {}
		learningPath.sequences.forEach((sequence, sequenceIndex) => {
			const courses = sequence.content.filter((item) => item.type === "course")
			courses.forEach((course, courseIndex) => {
				if (sequenceIndex === 0 && courseIndex === 0) {
					courseStatuses[course.targetId] = {
						status: "active"
					}
				} else if (sequenceIndex === 0 && !sequence.staggerProgress && !sequence.strictlyOrdered) {
					courseStatuses[course.targetId] = {
						status: "unlocked"
					}
				} else if (unlockTrigger === "finish_previous") {
					courseStatuses[course.targetId] = {
						status: "locked"
					}
				} else if (courseIndex === 0) {
					courseStatuses[course.targetId] = {
						status: "time_locked",
						unlockDay: sequence.unlockDelayDays + 1
					}
				} else if (!sequence.staggerProgress && !sequence.strictlyOrdered) {
					courseStatuses[course.targetId] = {
						status: "time_locked",
						unlockDay: sequence.unlockDelayDays + 1
					}
				} else if (sequence.staggerProgress && sequence.daysPerCourse) {
					courseStatuses[course.targetId] = {
						status: "time_locked",
						unlockDay: 1 + sequence.unlockDelayDays + courseIndex * sequence.daysPerCourse
					}
				} else {
					courseStatuses[course.targetId] = {
						status: "locked"
					}
				}
			})
		})
		return courseStatuses
	}

	calculateLearningPathWarnings() {
		const learningPath = this.rawContent
		let learningPathWarnings: string[] = []
		if (!learningPath.internalName) {
			learningPathWarnings.push(`You cannot leave your learning path name blank`)
		}
		if (learningPath.sequences.filter((item) => item.content.length === 0).length > 0) {
			const numSequencesWithoutContent = learningPath.sequences.filter((item) => item.content.length === 0).length
			const warning =
				numSequencesWithoutContent === 1
					? `You have 1 sequence without any content`
					: `You have ${numSequencesWithoutContent} sequences without any content`
			learningPathWarnings.push(warning)
		}
		return learningPathWarnings
	}

	calculateSequenceUpdates(originalLearningPath: LearningPath, deletedSequenceIds: UUID[]) {
		const learningPath = this.rawContent
		const updatedSequences: UpdatedSequences = {}
		const sequences = learningPath.sequences
		sequences.forEach((sequence) => {
			if (originalLearningPath.sequences.filter((item) => item.id === sequence.id).length === 0) {
				updatedSequences[sequence.id] = "added"
			} else {
				const originalSequence = originalLearningPath.sequences.filter((item) => item.id === sequence.id)[0]
				if (!_.isEqual(originalSequence, sequence)) {
					updatedSequences[sequence.id] = "updated"
				}
			}
		})
		deletedSequenceIds.forEach((sequenceId) => {
			updatedSequences[sequenceId] = "deleted"
		})
		return updatedSequences
	}
}

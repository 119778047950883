import React, { useState } from "react"
import styled from "styled-components"
import { Input, Typography } from "antd"
import { QuestionStyle } from "learnink-common/lib/domains/cards/questions/BaseQuestionCard.model"

const { TextArea } = Input
const { Text } = Typography

const arrayLabelMap = ["A", "B", "C", "D"]

const AnswerContainer = styled.div`
	margin: 4px 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding-right: 10px;
	background: white;
	border-style: solid;
	border-width: 2px;
`
const LetterIcon = styled.div`
	height: 28px;
	width: 28px;
	font-size: 14px;
	border-radius: 9999px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: var(--dark-grey);
	color: white;
	margin: 8px;
	margin-right: 0px;
`

const editableButtonStyle = {
	lineHeight: 1.4,
	margin: 0,
	whiteSpace: "pre-wrap",
	background: "transparent",
	borderWidth: 0,
	color: "black",
	padding: "4px 11px 4px 11px",
	flex: 1,
	fontSize: 12,
	height: 41,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center"
}

const editingButtonStyle = {
	lineHeight: 1.4,
	margin: 0,
	whiteSpace: "pre-wrap",
	background: "transparent",
	borderWidth: 0,
	fontSize: 12,
	flex: 1
}

interface Props {
	initialText: string
	placeholderText: string
	correct: boolean
	index: number
	updateAnswer: (text: string) => void
	style?: QuestionStyle
}

const Answer = ({ initialText, placeholderText, correct, index, updateAnswer, style }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [text, setText] = useState(initialText)

	const submitTitle = () => {
		updateAnswer(text)
		setIsOpen(false)
	}
	const revertTitle = () => {
		setText(initialText)
		setIsOpen(false)
	}
	const handleKeyDown = (event: any) => {
		if (event.keyCode === 13 && !event.shiftKey) {
			submitTitle()
		} else if (event.keyCode === 27) {
			revertTitle()
		}
	}

	if (style === "multChoiceImage") {
		return (
			<>
				{isOpen ? (
					<AnswerContainer
						key={index}
						style={{
							background: correct ? "var(--mid-green)" : "white",
							borderColor: correct ? "var(--dark-green)" : "var(--light-grey)",
							borderRadius: 6,
							paddingTop: 2,
							paddingBottom: 2
						}}
					>
						<TextArea
							onKeyDown={handleKeyDown}
							onChange={(e) => setText(e.target.value)}
							onBlur={submitTitle}
							onFocus={(e) => e.target.setSelectionRange(text.length, text.length)}
							autoFocus
							placeholder={placeholderText}
							value={text}
							rows={2}
							className="cardInput"
							// @ts-ignore
							style={{ ...editingButtonStyle, textAlign: "center" }}
						>
							{text}
						</TextArea>
					</AnswerContainer>
				) : (
					<AnswerContainer
						style={{
							background: correct ? "var(--mid-green)" : "white",
							borderColor: correct ? "var(--dark-green)" : "var(--light-grey)",
							borderRadius: 6,
							paddingTop: 2,
							paddingBottom: 2
						}}
						key={index}
						onClick={() => setIsOpen(true)}
					>
						{/* @ts-ignore */}
						<div style={{ ...editableButtonStyle, textAlign: "center" }}>
							{text !== "" ? text : <Text type="secondary">{placeholderText}</Text>}
						</div>
					</AnswerContainer>
				)}
			</>
		)
	}

	return (
		<>
			{isOpen ? (
				<AnswerContainer
					key={index}
					style={{
						background: correct ? "var(--mid-green)" : "white",
						borderColor: correct ? "var(--dark-green)" : "var(--light-grey)",
						borderRadius: 28
					}}
				>
					<LetterIcon>{arrayLabelMap[index]}</LetterIcon>
					<TextArea
						onKeyDown={handleKeyDown}
						onChange={(e) => setText(e.target.value)}
						onBlur={submitTitle}
						onFocus={(e) => e.target.setSelectionRange(text.length, text.length)}
						autoFocus
						placeholder={placeholderText}
						value={text}
						rows={2}
						className="cardInput"
						// @ts-ignore
						style={editingButtonStyle}
					>
						{text}
					</TextArea>
				</AnswerContainer>
			) : (
				<AnswerContainer
					style={{
						background: correct ? "var(--mid-green)" : "white",
						borderColor: correct ? "var(--dark-green)" : "var(--light-grey)",
						borderRadius: 28
					}}
					key={index}
					onClick={() => setIsOpen(true)}
				>
					<LetterIcon>{arrayLabelMap[index]}</LetterIcon>
					{/* @ts-ignore */}
					<div style={editableButtonStyle}>
						{text !== "" ? text : <Text type="secondary">{placeholderText}</Text>}
					</div>
				</AnswerContainer>
			)}
		</>
	)
}

export default Answer

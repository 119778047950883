import React from "react"
import styled from "styled-components"

const QuestionCardContainer = styled.div`
	background: white;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 0px 10px 0px;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
`
const QuestionImg = styled.img`
	width: calc(100% - 20px);
	height: 150px;
	border-radius: 9px;
	margin: 0px 10px 0px 10px;
	object-fit: cover;
`
const QuestionText = styled.div`
	min-height: 40px;
	font-size: 16px;
	color: black;
	line-height: 18px;
	text-align: left;
	font-weight: 600;
	margin: 12px;
	text-align: center;
`
const AnswerButtonContainer = styled.div`
	width: 100%;
	padding: 0px 12px 0px 12px;
	margin-bottom: 4px;
`
const AnswerBtn = styled.div`
	height: 40px;
	width: 100%;
	border: solid 2px var(--mid-grey);
	border-radius: 20px;
	background: white;
	text-align: left;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: var(--dark-grey);
	margin-bottom: 2%;
`
const AnswerIndex = styled.div`
	height: 25px;
	width: 25px;
	text-align: center;
	background: var(--dark-grey);
	line-height: 25px;
	color: white;
	font-size: 14px;
	font-weight: 600;
	border-radius: 99%;
	margin-left: 4px;
	text-transform: uppercase;
`
const AnswerText = styled.div`
	line-height: 14px;
	font-size: 14px;
	font-weight: 600;
	max-height: 28px;
	overflow: hidden;
	flex: 1;
	padding: 0px 10px 0px 10px;
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	max-width: 330px;
`

const SingleQuizQuestionCard = ({ singleQuestionContent }) => {
	return (
		<Wrapper>
			<QuestionCardContainer>
				<QuestionImg src={singleQuestionContent.content.question_image_url} />
				<QuestionText>{singleQuestionContent.content.question_text}</QuestionText>
				<AnswerButtonContainer>
					<AnswerBtn style={{ backgroundColor: "var(--mid-green)" }}>
						<AnswerIndex>A</AnswerIndex>
						<AnswerText>{singleQuestionContent.content.answer_data[0].answer_text}</AnswerText>
					</AnswerBtn>
					<AnswerBtn>
						<AnswerIndex>B</AnswerIndex>
						<AnswerText>{singleQuestionContent.content.answer_data[1].answer_text}</AnswerText>
					</AnswerBtn>
					<AnswerBtn>
						<AnswerIndex>C</AnswerIndex>
						<AnswerText>{singleQuestionContent.content.answer_data[2].answer_text}</AnswerText>
					</AnswerBtn>
					<AnswerBtn>
						<AnswerIndex>D</AnswerIndex>
						<AnswerText>{singleQuestionContent.content.answer_data[3].answer_text}</AnswerText>
					</AnswerBtn>
				</AnswerButtonContainer>
			</QuestionCardContainer>
		</Wrapper>
	)
}

export default SingleQuizQuestionCard

import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link } from "react-router-dom"
import { Tag } from "antd"

import { selectOrgId } from "../../../redux/org/org.selectors"
import { User } from "learnink-common/lib/domains/userManagement/users/User.model"
import { userTableColumns } from "./UserTable.model"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import UserTableQuery from "../../management/userTable/UserTableQuery"
import TableFilter from "../../management/userTable/TableFilter"
import { selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

const filterTypesNotInQueryString = ["phoneNumber", "userId", "customId"]

const Row = styled.div`
	width: 96%;
	text-align: left;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-bottom: 1rem;
`

const UserListFilters = ({ orgId, userList, premiumFeatureAccess, isAdmin }) => {
	const [tableFilters, setTableFilters] = useUrlQueryString("filters")
	const [filtersNotInQueryString, setFiltersNotInQueryString] = useState([])
	const tableFiltersArray = tableFilters ? JSON.parse(tableFilters) : []

	const removeFilter = (filter) => {
		if (filterTypesNotInQueryString.includes(filter.member)) {
			const newFilters = filtersNotInQueryString.filter(
				(item) =>
					item.member + item.operator + item.values.toString() !==
					filter.member + filter.operator + filter.values.toString()
			)
			setFiltersNotInQueryString(newFilters)
		} else {
			const existingFilters = JSON.parse(tableFilters)
			const newFilters = existingFilters.filter(
				(item) =>
					item.member + item.operator + item.values.toString() !==
					filter.member + filter.operator + filter.values.toString()
			)
			setTableFilters(JSON.stringify(newFilters))
		}
	}

	const setNewFilter = (activeFilter) => {
		const newFilter = [
			{
				member: activeFilter.member,
				operator: activeFilter.operator,
				values: activeFilter.values
			}
		]
		if (filterTypesNotInQueryString.includes(activeFilter.member)) {
			const newFilters = [...filtersNotInQueryString, ...newFilter]
			setFiltersNotInQueryString(newFilters)
		} else {
			const existingFilters = tableFilters ? JSON.parse(tableFilters) : []
			const newFilters = [...existingFilters, ...newFilter]
			setTableFilters(JSON.stringify(newFilters))
		}
	}

	const toggleRegistrationStatusFilter = (value) => {
		const member = "registrationStatus"
		const operator = "includes"
		const registrationStatus = tableFiltersArray.filter((item) => item.member === member)
		const filterOn = registrationStatus.length > 0
		if (filterOn) {
			const currentValues = registrationStatus[0].values
			const existingFilters = JSON.parse(tableFilters).filter((item) => item.member !== member)
			if (currentValues.includes(value)) {
				const newValues = currentValues.filter((item) => item !== value)
				if (newValues.length > 0) {
					const newFilters = [...existingFilters, { member, operator, values: newValues }]
					setTableFilters(JSON.stringify(newFilters))
				}
			} else {
				const newValues = [...currentValues, value]
				if (newValues.length > 0) {
					const newFilters = [...existingFilters, { member, operator, values: newValues }]
					setTableFilters(JSON.stringify(newFilters))
				}
			}
		} else {
			const currentValues = ["registered", "unregistered"]
			const newValues = currentValues.filter((item) => item !== value)
			setNewFilter({ member, operator, values: newValues })
		}
	}

	const registrationStatusFilter = tableFiltersArray.filter((item) => item.member === "registrationStatus")
	const registeredOn =
		registrationStatusFilter.length === 0
			? true
			: !!(registrationStatusFilter.length > 0 && registrationStatusFilter[0].values.includes("registered"))
	const unregisteredOn =
		registrationStatusFilter.length === 0
			? true
			: !!(registrationStatusFilter.length > 0 && registrationStatusFilter[0].values.includes("unregistered"))

	const filters = [...tableFiltersArray, ...filtersNotInQueryString].filter(
		(item) => item.member !== "registrationStatus"
	)

	const learningPathActions = premiumFeatureAccess.learningPaths === "unlocked" || isAdmin ? ["editLearningPath"] : []
	const learningPathColumns = premiumFeatureAccess.learningPaths === "unlocked" || isAdmin ? ["learningPathName"] : []

	return (
		<>
			<Row>
				<Tag
					className="bigTagFilter"
					color={registeredOn ? "#5bbf32" : null}
					onClick={() => toggleRegistrationStatusFilter("registered")}
				>
					{userList.filter((item) => item.registrationStatus === "registered").length} registered users
				</Tag>
				<Tag
					className="bigTagFilter"
					color={unregisteredOn ? "#5bbf32" : null}
					onClick={() => toggleRegistrationStatusFilter("unregistered")}
				>
					{userList.filter((item) => item.registrationStatus === "unregistered").length} unregistered users
				</Tag>
				<Link to={`/org/${orgId}/manage/archived-users`}>
					<Tag className="bigTagFilter">See archived users</Tag>
				</Link>
			</Row>
			<TableFilter
				filters={filters}
				removeFilter={removeFilter}
				setNewFilter={setNewFilter}
				filterMembers={[
					"userGroups",
					"appDownloaded",
					"lastActive",
					"phoneNumber",
					"userId",
					"customId",
					"registeredAt",
					"lastActive"
				]}
			/>
			<UserTableQuery
				registeredOn={registeredOn}
				unregisteredOn={unregisteredOn}
				archivedOn={false}
				filters={filters}
				orgId={orgId}
				userList={userList}
				actions={["archive", "notify", "editUserGroups", "delete", "export", ...learningPathActions]}
				columns={userTableColumns.filter((item) =>
					[...Object.keys(User.shape), "userGroupNames", ...learningPathColumns].includes(item.id)
				)}
				hiddenColumns={["firstName", "lastName"]}
				notifyButtonOn
				sidePanel="userProfile"
			/>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	isAdmin: selectIsUserAdmin
})

export default connect(mapStateToProps)(UserListFilters)
